// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-gallery-js": () => import("./../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-hotsite-js": () => import("./../../src/pages/hotsite.js" /* webpackChunkName: "component---src-pages-hotsite-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-mais-cursos-colorindo-fan-art-js": () => import("./../../src/pages/mais-cursos/colorindo-fan-art.js" /* webpackChunkName: "component---src-pages-mais-cursos-colorindo-fan-art-js" */),
  "component---src-pages-mais-cursos-combo-ultimate-js": () => import("./../../src/pages/mais-cursos/combo-ultimate.js" /* webpackChunkName: "component---src-pages-mais-cursos-combo-ultimate-js" */),
  "component---src-pages-mais-cursos-index-js": () => import("./../../src/pages/mais-cursos/index.js" /* webpackChunkName: "component---src-pages-mais-cursos-index-js" */),
  "component---src-pages-polices-js": () => import("./../../src/pages/polices.js" /* webpackChunkName: "component---src-pages-polices-js" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-tutoriais-como-desenhar-olhos-js": () => import("./../../src/pages/tutoriais/como-desenhar-olhos.js" /* webpackChunkName: "component---src-pages-tutoriais-como-desenhar-olhos-js" */),
  "component---src-pages-tutoriais-index-js": () => import("./../../src/pages/tutoriais/index.js" /* webpackChunkName: "component---src-pages-tutoriais-index-js" */)
}

